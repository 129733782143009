.coverWrapper {
  padding-bottom: 42.5675675675676%;
}

.coverWrapperImage {
  padding-bottom: 100%;
}

.coverWrapperVideo {
  padding-bottom: calc((9 / 16) * 100%);
}

.coverMediaGallery {
  padding-bottom: 100%;
}

@screen lg {
  .coverMediaGallery {
    padding-bottom: calc((9 / 16) * 100%);
  }
}
