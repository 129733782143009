.imageContainerWide,
.imageContainerSquare,
.imageContainerPortrait {
  position: relative;
  padding-bottom: 100%;
}

.gridGap {
  gap: 10px;
}

.description {
}

@screen md {
  .imageContainerWide {
    padding-bottom: calc(486 / 983 * 100%);
  }

  .imageContainerPortrait {
    padding-bottom: calc(983 / 486 * 100%);
  }

  .singleTile {
    position: relative;
  }

  .singleTile::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
  }

  .singleTile img {
    transition: all 0.3s;
  }

  .singleTile:hover::after {
    background: rgba(0, 0, 0, 0.16);
  }

  .singleTile:hover img {
    transform: scale(1.1);
  }
}

.title {
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@screen lg {
  .title {
    -webkit-line-clamp: 3;
  }
}

@screen 2xl {
  .title {
    -webkit-line-clamp: 4;
  }
}
