.richText *:first-child {
  @apply mt-0;
}

.richText * {
  @apply mt-5;
}

.richText li {
  @apply mt-0;
}

.richText ul > li {
  @apply list-disc;
}

.richText ol > li {
  @apply list-decimal;
}

.richText ul,
.richText ol {
  @apply pl-5;
}

.richText a {
  @apply underline cursor-pointer hover:text-skyBlue;
}

.richText h1 {
  @apply text-3xl font-bold;
}

.richText h2 {
  @apply text-2xl font-bold;
}

.richText h3 {
  @apply text-xl font-bold;
}

.richText h4 {
  @apply text-lg font-bold;
}

.richText blockquote {
  @apply italic;
}
